import React, {useState, useMemo} from 'react';
import {useProductAttributes} from '../../../../state';
import {TestResult} from "../assets/TestResult";
import {SaveTestBtn, ErrorMessage} from "../../../../../../general";

interface Question {
    answer: boolean | null;
    id: number;
    text: string;
}

interface TrueOrFalseSubpunct {
    id: number;
    questions: Question[];
    text: string;
}

interface AnswersState {
    [key: string]: boolean | null;
}

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const TrueOrFalse = () => {
    const {
        testData,
        loading,
        saveTestData,
        productSku,
        mutationError,
        clearMutationError,
        isTestCompleted,
        testResult,
        setTestCompleted
    } = useProductAttributes() || {};

    const [userAnswers, setUserAnswers] = useState<AnswersState>({});
    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const decodedTestData = useMemo(() => {
        if (!testData?.trueOrFalse) return null;

        return {
            trueOrFalse: testData.trueOrFalse.map(item => ({
                ...item,
                text: decodeHtml(item.text),
                questions: item.questions.map(question => ({
                    ...question,
                    text: decodeHtml(question.text)
                }))
            }))
        };
    }, [testData]);

    const handleAnswerChange = (itemId: number, questionId: number, value: boolean) => {
        const key = `${itemId}-${questionId}`;
        setUserAnswers(prev => ({
            ...prev,
            [key]: value
        }));

        if (errors[key]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[key];
                return newErrors;
            });
        }
    };

    const calculateScore = (userAnswers: {
        [key: string]: boolean | null
    }, questions: TrueOrFalseSubpunct[]): number => {
        let correctAnswers = 0;
        let totalQuestions = 0;

        questions.forEach(item => {
            item.questions.forEach(question => {
                const key = `${item.id}-${question.id}`;
                if (userAnswers[key] === question.answer) {
                    correctAnswers++;
                }
                totalQuestions++;
            });
        });

        return Number(((correctAnswers / totalQuestions) * 100).toFixed(2));
    };

    const handleSave = async () => {
        const newErrors = {};
        decodedTestData.trueOrFalse.forEach(item => {
            item.questions.forEach(question => {
                const key = `${item.id}-${question.id}`;
                if (userAnswers[key] === null || userAnswers[key] === undefined) {
                    newErrors[key] = 'Selectează un răspuns';
                }
            });
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            setSaveMessage('Te rugăm să răspunzi la toate întrebările înainte de a finaliza testul.');
            return;
        }

        setIsSaving(true);
        clearMutationError();

        try {
            const userTestData = decodedTestData.trueOrFalse.map(item => ({
                ...item,
                questions: item.questions.map(question => ({
                    ...question,
                    user_answer: userAnswers[`${item.id}-${question.id}`],
                    right_answer: question.answer
                }))
            }));

            const testDataToSave = {
                trueOrFalse: userTestData,
                timestamp: new Date().toISOString()
            };

            const score = calculateScore(userAnswers, decodedTestData.trueOrFalse);

            const result = await saveTestData({
                product_sku: productSku,
                test_type: 'true_or_false',
                test_data: JSON.stringify(testDataToSave),
                test_score: score
            });

            if (result) {
                setSaveMessage('Testul a fost salvat!');
                setTestCompleted(true, {score: score});
            }
        } catch (error) {
            console.error('Eroare la salvarea răspunsurilor:', error);
            setSaveMessage(null);
        } finally {
            setIsSaving(false);
        }
    };

    if (!decodedTestData?.trueOrFalse?.length) return null;

    if (isTestCompleted && testResult) {
        return <TestResult score={testResult.score} saveMessage={saveMessage}/>;
    }

    return (
        <div
            className="true-or-false-container max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border-2 border-double border-medium-blue
            shadow-round-shadow rounded border-x border-y"
        >
            <div className="flex flex-col items-start justify-start gap-3">
                <h2 className="uppercase font-font-p font-semibold text-text-color text-2xl">
                    Adevarat sau fals
                </h2>
                <p className="font-font-p font-semibold text-text-color text-xl">
                    Citeste cu atentie testul de mai jos, pentru a putea raspunde la cerintele formulate:
                </p>
            </div>
            {decodedTestData.trueOrFalse.map((item: TrueOrFalseSubpunct) => (
                <div
                    key={item.id}
                    className="true-or-false-item w-full mb-6 flex flex-col items-start justify-start gap-5"
                >
                    <p dangerouslySetInnerHTML={{__html: item.text}}
                       className="font-font-s font-normal text-text-color text-xl bg-white
                       p-3 border-2 border-double border-medium-blue rounded border-x border-y"
                    />
                    <p className="font-font-p font-semibold text-text-color text-xl">
                        Cerinte:
                    </p>
                    {item.questions.map((question) => (
                        <div key={question.id}
                             className={'question w-full p-3 border-2 border-double border-medium-blue rounded border-x border-y flex flex-col gap-3'}
                        >
                            <p className="font-font-s text-lg italic font-bold ">
                                Intrebarea {question.id}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: question.text}}
                               className="font-font-s font-normal text-text-color text-xl bg-white p-3"
                            />
                            <p className="font-font-s text-lg italic font-bold ">
                                Raspunsurile:
                            </p>
                            <div
                                className="answers font-font-s font-normal text-text-color text-xl flex flex-row gap-5"
                            >
                                <label className="label font-font-s font-normal text-text-color text-xl cursor-pointer">
                                    <input
                                        className="w-6 h-6 cursor-pointer"
                                        type="radio"
                                        checked={userAnswers[`${item.id}-${question.id}`] === true}
                                        onChange={() => handleAnswerChange(item.id, question.id, true)}
                                    />
                                    Adevărat
                                </label>
                                <label
                                    className="answers font-font-s font-normal text-text-color text-xl cursor-pointer">
                                    <input
                                        className="w-6 h-6 cursor-pointer"
                                        type="radio"
                                        checked={userAnswers[`${item.id}-${question.id}`] === false}
                                        onChange={() => handleAnswerChange(item.id, question.id, false)}
                                    />
                                    Fals
                                </label>
                            </div>
                            {errors[`${item.id}-${question.id}`] && (
                                <p className="error-message text-red-500 font-font-s text-xl">
                                    {errors[`${item.id}-${question.id}`]}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <div className="flex justify-center items-center flex-col">
                <div className="flex gap-4">
                    <SaveTestBtn
                        onClick={handleSave}
                        disabled={Object.keys(errors).length > 0}
                        isSaving={isSaving}
                    />
                </div>
                {saveMessage && <p className="save-message mt-2 text-olive-color font-font-s text-xl">{saveMessage}</p>}
                {mutationError && <ErrorMessage message={mutationError}/>}
            </div>
        </div>
    );
};
