import React, {createContext, useContext, useState, useEffect, ReactNode, useMemo} from 'react';
import {request} from '../../../Infrastructure';
import {useQuery} from "@tanstack/react-query";
import {getCustomerTestData} from '../../queries';

interface CustomerTestData {
    entity_id: number;
    product_sku: string;
    test_type: string;
    test_increment: number;
    test_code: string;
    test_data: string;
    class_lvl_code: string;
    module_lvl_code: string;
    difficulty_lvl_code: string;
    test_score: number;
    created_at: string;
    displayIndex?: number;
}

interface CustomerData {
    firstname: string;
    lastname: string;
    email: string;
    customer_test_data: CustomerTestData[];
}

interface CustomerTestDataContextValue {
    customerData: CustomerData | null;
    loading: boolean;
    error: any;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    pageSize: number;
    totalPages: number;
    paginatedTestData: CustomerTestData[];
}

const CustomerTestDataContext = createContext<CustomerTestDataContextValue | undefined>(undefined);

export const CustomerTestDataProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [customerData, setCustomerData] = useState<CustomerData | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 15;

    const testData = customerData?.customer_test_data?.[0]?.test_data;

    const customerTestDataQuery = useQuery({
        queryKey: ['customerTestData'],
        queryFn: async () => {
            return request(getCustomerTestData);
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (customerTestDataQuery?.data?.data?.customer) {
            setCustomerData(customerTestDataQuery.data.data.customer);
        }
    }, [customerTestDataQuery?.data]);

    const sortedTestData = useMemo(() => {
        if (!customerData) return [];
        return [...customerData.customer_test_data]
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .map((test, index) => ({
                ...test,
                displayIndex: customerData.customer_test_data.length - index
            }));
    }, [customerData]);

    const totalPages = useMemo(() => {
        return Math.ceil(sortedTestData.length / pageSize);
    }, [sortedTestData, pageSize]);

    const paginatedTestData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return sortedTestData.slice(startIndex, endIndex);
    }, [sortedTestData, currentPage, pageSize]);

    return (
        <CustomerTestDataContext.Provider value={{
            customerData,
            loading: customerTestDataQuery.isLoading,
            error: customerTestDataQuery.error,
            currentPage,
            setCurrentPage,
            pageSize,
            totalPages,
            paginatedTestData,
        }}>
            {children}
        </CustomerTestDataContext.Provider>
    );
};

export const useCustomerTestData = () => {
    const context = useContext(CustomerTestDataContext);
    if (!context) {
        throw new Error('useCustomerTestData must be used within a CustomerTestDataProvider');
    }
    return context;
};
