import React from 'react';
import {useTestDataState} from '../../../state';
import {TrueOrFalse} from './types/TrueOrFalse';
import {MultipleAnswer} from "./types/MultipleAnswer";
import {MultiChoose} from "./types/MultiChoose";
import {NationalEvaluation} from "./types/NationalEvaluation";

export const TestSelector: React.FC<{ productId: number }> = ({productId}) => {
    const {categories, loading, error, testCategory} = useTestDataState();

    if (loading) return <div>Loading...</div>;
    if (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        return <div>Error: {errorMessage}</div>;
    }

    const hasTrueFalseCategory = categories && testCategory && categories.some(
        category => {
            return category.id.toString() === testCategory.trueFalse?.id;
        }
    );

    const hasMultipleChoiceCategory = categories && testCategory && categories.some(
        category => {
            return category.id.toString() === testCategory.multipleChoice?.id;
        }
    );

    const hasGridCategory = categories && testCategory && categories.some(
        category => {
            return category.id.toString() === testCategory.grid?.id;
        }
    );

    const hasEvaluationCategory = categories && testCategory && categories.some(
        category => {
            return category.id.toString() === testCategory.nationalEvaluation?.id;
        }
    );

    return (
        <div>
            {hasTrueFalseCategory && <TrueOrFalse productId={productId}/>}
            {hasMultipleChoiceCategory && <MultipleAnswer productId={productId}/>}
            {hasGridCategory && <MultiChoose productId={productId}/>}
            {hasEvaluationCategory && <NationalEvaluation productId={productId}/>}
            {!hasTrueFalseCategory && !hasMultipleChoiceCategory && !hasGridCategory && !hasEvaluationCategory &&
                <p>Nu există teste disponibile pentru acest tip de produs.</p>}
        </div>
    );
};
