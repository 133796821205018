import React from 'react';
import {GridAnswerDisplay} from './assets/GridAnswerDisplay';
import {TrueFalseDisplay} from './assets/TrueFalseDisplay';
import {MultipleAnswerDisplay} from './assets/MultipleAnswerDisplay';
import {RequirementDisplay} from './assets/RequirementDisplay';
import {ScoreSummary} from './assets/ScoreSummary';

export const NationalEvaluationTestView = ({data}) => {
    console.log(data);

    return (
        <div
            className="national-evaluation max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border border-double border-medium-blue shadow-round-shadow rounded border-x border-y">
            <h2 className="uppercase font-font-p font-semibold text-text-color text-2xl">
                Evaluare Nationala - Rezultate
            </h2>

            <ScoreSummary scoreDetails={data?.score_details}/>

            <section className="initial-texts mb-8">
                <h2 className={'font-font-p font-semibold text-2xl text-text-color mb-3'}>
                    Subiectul I
                </h2>
                {data?.initialTexts?.map((item) =>
                    item.texts.map((text) => (
                        <div key={text.id} className={'flex flex-col gap-3 mb-3'}>
                            <p className="font-font-p font-semibold text-text-color text-xl">
                                Textul {text.id}
                            </p>
                            <p
                                dangerouslySetInnerHTML={{__html: text.text}}
                                className={'font-font-s font-normal text-text-color text-xl bg-white p-3 ' +
                                    'border border-double border-medium-blue rounded border-x border-y'}
                            />
                        </div>
                    ))
                )}
            </section>

            {/* Part A */}
            <section className="partA mb-8">
                <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea A</h2>
                {/* Multiple Answers */}
                {data?.partA?.multiAnswers?.map((item) => (
                    <div key={item.id}>
                        {item.subpuncte?.map((subpunct, index) => (
                            <div key={subpunct.id}
                                 className={'flex flex-col gap-3 border border-double border-medium-blue rounded border-x border-y p-3 mb-8'}>
                                <p className="text-2xl font-semibold text-text-color mb-4 italic">
                                    {index + 1}
                                </p>
                                <p className="font-font-s text-lg italic font-bold">
                                    Cerinta
                                </p>
                                <div className="bg-white rounded p-3">
                                    <p
                                        dangerouslySetInnerHTML={{__html: subpunct.text}}
                                        className="font-font-s font-normal text-text-color text-xl"
                                    />
                                </div>
                                <MultipleAnswerDisplay
                                    subpunct={subpunct}
                                    score={subpunct.score}
                                />
                            </div>
                        ))}
                    </div>
                ))}

                {/* Grid Questions */}
                {data?.partA?.gridSubjects?.map((item, index) => (
                    <div key={item.id}>
                        {item.subpuncte?.map((subpunct) => (
                            <div key={subpunct.id}
                                 className={'flex flex-col gap-3 border border-double border-medium-blue rounded border-x border-y p-3 mb-8'}>
                                <p className="text-2xl font-semibold text-text-color mb-4 italic">
                                    {index + 2}
                                </p>
                                <p className="font-font-s text-lg italic font-bold">
                                    Cerinta
                                </p>
                                <div className="bg-white rounded p-3">
                                    <p
                                        dangerouslySetInnerHTML={{__html: subpunct.text}}
                                        className="font-font-s font-normal text-text-color text-xl"
                                    />
                                </div>
                                <GridAnswerDisplay
                                    rightAnswers={subpunct.right_answer}
                                    userAnswer={subpunct.userAnswer}
                                    score={subpunct.score}
                                />
                            </div>
                        ))}
                    </div>
                ))}

                {/* True/False Questions */}
                <div className="flex flex-col gap-3 w-full border border-double border-medium-blue rounded border-x border-y p-3 mb-8">
                    <p className="text-2xl font-semibold text-text-color mb-4 italic">
                        {5}
                    </p>
                    {data?.partA?.trueFalseSubjects?.map((subject) => (
                        <div className={''}>
                            <div key={subject.id}>
                                {subject.questions?.map((question) => (
                                    <div key={question.id}
                                         className={'flex flex-col gap-3 mb-8'}>
                                        <p className="font-font-s text-lg italic font-bold">
                                            Cerinta
                                        </p>
                                        <div className="bg-white p-3 rounded">
                                            <p
                                                dangerouslySetInnerHTML={{__html: question.text}}
                                                className="font-font-s font-normal text-text-color text-xl"
                                            />
                                        </div>
                                        <TrueFalseDisplay
                                            question={question}
                                            score={question.score}
                                        />
                                    </div>


                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Requirements */}
                {data?.partA?.requirementSubjects?.map((requirement, index) => (
                    <div key={requirement.id}
                         className="border border-double border-medium-blue rounded border-x border-y p-3 mb-8">
                        <p className="text-2xl font-semibold text-text-color mb-4 italic">
                            {index + 6}
                        </p>
                        <p className="font-font-s text-lg italic font-bold">
                            Cerinta
                        </p>
                        <div className="bg-white p-3 rounded">
                            <p
                                dangerouslySetInnerHTML={{__html: requirement.text}}
                                className="font-font-s font-normal text-text-color text-xl"
                            />
                        </div>
                        <RequirementDisplay requirement={requirement}/>
                    </div>
                ))}
            </section>

            {/* Part B - Similar structure as Part A */}
            <section className="partB mb-8">
                <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea B</h2>
                {/* Grid Questions */}
                {data?.partB?.gridSubjects?.map((item, index) => (
                    <div key={item.id}>
                        {item.subpuncte?.map((subpunct) => (
                            <div key={subpunct.id}
                                 className={'flex flex-col gap-3 border border-double border-medium-blue rounded border-x border-y p-3 mb-8'}>
                                <p className="text-2xl font-semibold text-text-color mb-4 italic">
                                    {index + 1}
                                </p>
                                <p className="font-font-s text-lg italic font-bold">
                                    Cerinta
                                </p>
                                <div className="bg-white rounded p-3">
                                    <p
                                        dangerouslySetInnerHTML={{__html: subpunct.text}}
                                        className="font-font-s font-normal text-text-color text-xl"
                                    />
                                </div>
                                <GridAnswerDisplay
                                    rightAnswers={subpunct.right_answer}
                                    userAnswer={subpunct.userAnswer}
                                    score={subpunct.score}
                                />
                            </div>
                        ))}
                    </div>
                ))}

                {/* Multiple Answers */}
                {data?.partB?.multiAnswers?.map((item) => (
                    <div key={item.id}>
                        {item.subpuncte?.map((subpunct, index) => (
                            <div key={subpunct.id}
                                 className={'flex flex-col gap-3 border border-double border-medium-blue rounded border-x border-y p-3 mb-8'}>
                                <p className="text-2xl font-semibold text-text-color mb-4 italic">
                                    {index + 5}
                                </p>
                                <p className="font-font-s text-lg italic font-bold">
                                    Cerinta
                                </p>
                                <div className="bg-white rounded p-3">
                                    <p
                                        dangerouslySetInnerHTML={{__html: subpunct.text}}
                                        className="font-font-s font-normal text-text-color text-xl"
                                    />
                                </div>
                                <MultipleAnswerDisplay
                                    subpunct={subpunct}
                                    score={subpunct.score}
                                />
                            </div>
                        ))}
                    </div>
                ))}

                {/* Requirements */}
                {data?.partB?.requirementSubjects?.map((requirement, index) => (
                    <div key={requirement.id}
                         className="border border-double border-medium-blue rounded border-x border-y p-3 mb-8">
                        <p className="text-2xl font-semibold text-text-color mb-4 italic">
                            {index + 6}
                        </p>
                        <p className="font-font-s text-lg italic font-bold">
                            Cerinta
                        </p>
                        <div className="bg-white p-3 rounded">
                            <p
                                dangerouslySetInnerHTML={{__html: requirement.text}}
                                className="font-font-s font-normal text-text-color text-xl"
                            />
                        </div>
                        <RequirementDisplay requirement={requirement}/>
                    </div>
                ))}
            </section>

            {/* Subiectul 2 */}
            <section className="subiect2 mb-8">
                <h2 className={'font-font-p font-semibold text-2xl text-text-color mb-3'}>
                    Subiectul al II-lea
                </h2>
                {data?.requirementSubjects?.map((requirement) => (
                    <div key={requirement.id} className="space-y-4">
                        <div className="bg-white p-3 border border-double border-medium-blue rounded">
                            <p
                                dangerouslySetInnerHTML={{__html: requirement.text}}
                                className="font-font-s font-normal text-text-color text-xl"
                            />
                        </div>
                        <div className="bg-gray-50 p-4 rounded">
                            <div className="mb-4">
                                <div className={'flex items-start gap-2'}>
                                    <span className="font-semibold text-gray-700">Punctaj acordat:</span>
                                    <span className="text-blue-600 font-medium">
                                        {data?.score_details?.subiect2?.score} puncte
                                    </span>
                                </div>
                                <h4 className="font-semibold text-gray-700">Răspunsul tău:</h4>
                                <p className="mt-2 text-gray-600">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
};
