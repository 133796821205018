import React from "react";

export const RequirementDisplay = ({requirement}) => {
    return (
        <div className="mt-4 bg-gray-50 rounded">
            <div className="flex items-start gap-2">
                <span className="font-semibold text-gray-700">Punctaj acordat:</span>
                <span className="text-blue-600 font-medium">
                        {requirement?.score ? requirement.score.toFixed(2) : '0'} puncte
                    </span>
            </div>
            <div className="mb-4">
                <h4 className="font-semibold text-gray-700">Răspunsul tău:</h4>
                <p className="mt-2 text-gray-600">{requirement.userAnswer || 'Niciun răspuns oferit'}</p>
            </div>
        </div>
    );
};
