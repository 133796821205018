import React from 'react';
import { AlertTriangle } from 'lucide-react';

export const ScoreSummary = ({scoreDetails}) => {
    if (!scoreDetails) return null;

    return (
        <div className="mt-6 space-y-4">
            <div className="p-4 bg-blue-50 rounded-lg">
                <h3 className="text-lg font-semibold mb-3">Punctaj total</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <h4 className="font-medium">Subiectul 1A</h4>
                        <p>{scoreDetails.subiect1A.score}/{scoreDetails.subiect1A.max_score} puncte</p>
                    </div>
                    <div>
                        <h4 className="font-medium">Subiectul 1B</h4>
                        <p>{scoreDetails.subiect1B.score}/{scoreDetails.subiect1B.max_score} puncte</p>
                    </div>
                    <div>
                        <h4 className="font-medium">Subiectul 2</h4>
                        <p>{scoreDetails.subiect2.score}/{scoreDetails.subiect2.max_score} puncte</p>
                    </div>
                    <div>
                        <h4 className="font-medium">Din oficiu</h4>
                        <p>{scoreDetails.puncte_oficiu} puncte</p>
                    </div>
                    <div className="md:col-span-2">
                        <h4 className="font-medium text-lg">Total final</h4>
                        <p className="text-lg font-semibold">{scoreDetails.total.score}/{scoreDetails.total.max_score} puncte</p>
                    </div>
                </div>
            </div>

            <div className="p-4 bg-amber-50 border border-amber-200 rounded-lg text-amber-800">
                <div className="flex items-start gap-3">
                    <AlertTriangle className="w-5 h-5 mt-1 flex-shrink-0 text-amber-500" />
                    <div>
                        <h4 className="font-medium mb-2">Notă importantă privind evaluarea</h4>
                        <p className="text-sm leading-relaxed">
                            În acest moment, din păcate, nu vă putem estima răspunsurile pentru compuneri.
                            Acestea sunt evaluate automat la un punctaj de 70% din punctajul maxim acordat.
                            Lucrăm la implementarea unei soluții de corectare AI. După implementare, veți avea
                            opțiunea să solicitați evaluarea acestor tipuri de teste și de către unul dintre profesorii noștri.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
