import React, {useState, useEffect, useMemo} from 'react';
import {useProductAttributes} from '../../../../state';
import {useWindowSize, SaveTestBtn, ErrorMessage} from "../../../../../../general";
import {TrueFalseQuestionGroup} from "./testParts/TrueFalseQuestionGroup";
import {GridQuestionGroup} from "./testParts/GridQuestionGroup";
import {MultipleAnswerGroup} from "./testParts/MultipleAnswerGroup";
import {EvNatTestResult} from "../assets/EvNatTestResult";

export const NationalEvaluation = () => {
    const {
        testData,
        loading,
        saveTestData,
        productSku,
        mutationError,
        clearMutationError,
        isTestCompleted,
        testResult,
        setTestCompleted
    } = useProductAttributes() || {};

    // Starea pentru răspunsurile utilizatorului
    const [userAnswers, setUserAnswers] = useState({
        multipleChoice: {},
        grid: {},
        trueFalse: {},
        textAnswers: {}
    });

    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const decodedTestData = useMemo(() => {
        if (!testData?.nationalEvaluation?.[0]) return null;
        return testData.nationalEvaluation[0];
    }, [testData]);

    const normalizeText = (text: string): string => {
        return text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .trim();
    };

    const handleRequirementChange = (requirementId: number, value: string, part: string) => {
        const key = `${part}-requirement-${requirementId}`;
        setUserAnswers(prev => ({
            ...prev,
            textAnswers: {
                ...prev.textAnswers,
                [key]: value
            }
        }));

        // Ștergem eroarea dacă există
        if (errors[key]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[key];
                return newErrors;
            });
        }
    };

    const handleAnswerChange = (itemId: number, questionId: number, answerId: number, value: string, part: string) => {
        const key = `${part}-${itemId}-${questionId}-${answerId}`;
        setUserAnswers(prev => ({
            ...prev,
            multipleChoice: {
                ...prev.multipleChoice,
                [key]: value
            }
        }));

        // Verifică dacă răspunsurile sunt identice pentru aceeași întrebare
        const currentAnswers = userAnswers.multipleChoice || {};
        for (let i = 1; i <= 2; i++) {
            if (i !== answerId) {
                const otherKey = `${part}-${itemId}-${questionId}-${i}`;
                if (currentAnswers[otherKey] === value) {
                    setErrors(prev => ({
                        ...prev,
                        [key]: 'Răspunsurile nu pot fi identice'
                    }));
                    break;
                }
            }
        }
    };

    const handleGridChange = (subjectId: number, subpunctId: number, answerId: number, part: string) => {
        setUserAnswers(prev => ({
            ...prev,
            grid: {
                ...prev.grid,
                [`${part}-${subjectId}-${subpunctId}`]: answerId
            }
        }));

        const errorKey = `grid-${part}-${subjectId}-${subpunctId}`;
        if (errors[errorKey]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[errorKey];
                return newErrors;
            });
        }
    };

    const handleTrueFalseChange = (subjectId: number, questionId: number, value: boolean, part: string) => {
        setUserAnswers(prev => ({
            ...prev,
            trueFalse: {
                ...prev.trueFalse,
                [`${part}-${subjectId}-${questionId}`]: value
            }
        }));

        const errorKey = `tf-${part}-${subjectId}-${questionId}`;
        if (errors[errorKey]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[errorKey];
                return newErrors;
            });
        }
    };

    const splitTrueFalseQuestions = (questions:any[]) => {
        const firstGroup = questions.slice(0, 3);
        const secondGroup = questions.slice(3);
        return {firstGroup, secondGroup};
    };

    const handleSubmit = async () => {
        // Validare răspunsuri multiple
        const newErrors = {};
        let hasErrors = false;

        if (decodedTestData.requirementSubjects) {
            decodedTestData.requirementSubjects.forEach(requirement => {
                const key = `subject2-requirement-${requirement.id}`;
                if (!userAnswers.textAnswers[key]?.trim()) {
                    newErrors[key] = 'Acest câmp este obligatoriu';
                    hasErrors = true;
                }
            });
        }

        if (decodedTestData.partA?.requirementSubjects) {
            decodedTestData.partA.requirementSubjects.forEach(requirement => {
                const key = `partA-requirement-${requirement.id}`;
                if (!userAnswers.textAnswers[key]?.trim()) {
                    newErrors[key] = 'Acest câmp este obligatoriu';
                    hasErrors = true;
                }
            });
        }

        if (decodedTestData.partB?.requirementSubjects) {
            decodedTestData.partB.requirementSubjects.forEach(requirement => {
                const key = `partB-requirement-${requirement.id}`;
                if (!userAnswers.textAnswers[key]?.trim()) {
                    newErrors[key] = 'Acest câmp este obligatoriu';
                    hasErrors = true;
                }
            });
        }

        if (decodedTestData.partA?.multiAnswers) {
            decodedTestData.partA.multiAnswers.forEach(item => {
                item.subpuncte.forEach(question => {
                    question.answers.forEach(answer => {
                        const key = `partA-${item.id}-${question.id}-${answer.id}`;
                        if (!userAnswers.multipleChoice[key]?.trim()) {
                            newErrors[key] = 'Acest câmp este obligatoriu';
                            hasErrors = true;
                        }
                    });
                });
            });
        }

        if (decodedTestData.partB?.multiAnswers) {
            decodedTestData.partB.multiAnswers.forEach(item => {
                item.subpuncte.forEach(question => {
                    question.answers.forEach(answer => {
                        const key = `partB-${item.id}-${question.id}-${answer.id}`;
                        if (!userAnswers.multipleChoice[key]?.trim()) {
                            newErrors[key] = 'Acest câmp este obligatoriu';
                            hasErrors = true;
                        }
                    });
                });
            });
        }

        if (decodedTestData.partA?.gridSubjects) {
            decodedTestData.partA.gridSubjects.forEach(subject => {
                subject.subpuncte.forEach(subpunct => {
                    if (!userAnswers.grid[`partA-${subject.id}-${subpunct.id}`]) {
                        newErrors[`grid-partA-${subject.id}-${subpunct.id}`] = 'Selectează un răspuns';
                        hasErrors = true;
                    }
                });
            });
        }

        if (decodedTestData.partB?.gridSubjects) {
            decodedTestData.partB.gridSubjects.forEach(subject => {
                subject.subpuncte.forEach(subpunct => {
                    if (!userAnswers.grid[`partB-${subject.id}-${subpunct.id}`]) {
                        newErrors[`grid-partB-${subject.id}-${subpunct.id}`] = 'Selectează un răspuns';
                        hasErrors = true;
                    }
                });
            });
        }

        if (decodedTestData.partA?.trueFalseSubjects) {
            decodedTestData.partA.trueFalseSubjects.forEach(subject => {
                subject.questions.forEach(question => {
                    const key = `partA-${subject.id}-${question.id}`;
                    if (userAnswers.trueFalse[key] === undefined) {
                        newErrors[`tf-${key}`] = 'Selectează un răspuns';
                        hasErrors = true;
                    }
                });
            });
        }

        if (decodedTestData.partB?.trueFalseSubjects) {
            decodedTestData.partB.trueFalseSubjects.forEach(subject => {
                subject.questions.forEach(question => {
                    const key = `partB-${subject.id}-${question.id}`;
                    if (userAnswers.trueFalse[key] === undefined) {
                        newErrors[`tf-${key}`] = 'Selectează un răspuns';
                        hasErrors = true;
                    }
                });
            });
        }

        setErrors(newErrors);
        if (hasErrors) {
            setSaveMessage('Te rugăm să completezi toate răspunsurile obligatorii.');
            return;
        }

        setIsSaving(true);
        clearMutationError();

        try {
            // Pregătirea datelor pentru salvare care include atât datele inițiale cât și răspunsurile utilizatorului
            const testDataToSave = {
                // Păstrăm textele inițiale
                initialTexts: decodedTestData.initialTexts,

                partA: {
                    // Răspunsuri multiple
                    multiAnswers: decodedTestData.partA?.multiAnswers?.map(item => ({
                        id: item.id,
                        subpuncte: item.subpuncte.map(question => ({
                            id: question.id,
                            text: question.text,
                            answers: question.answers.map(answer => ({
                                id: answer.id,
                                right_answer: answer.words, // Răspunsurile corecte
                                userAnswer: userAnswers.multipleChoice[`partA-${item.id}-${question.id}-${answer.id}`] || ''
                            }))
                        }))
                    })),

                    // Grid-uri
                    gridSubjects: decodedTestData.partA?.gridSubjects?.map(subject => ({
                        id: subject.id,
                        subpuncte: subject.subpuncte.map(subpunct => ({
                            id: subpunct.id,
                            text: subpunct.text,
                            right_answer: subpunct.answer,
                            userAnswer: userAnswers.grid[`partA-${subject.id}-${subpunct.id}`] || null
                        }))
                    })),

                    // Adevărat/Fals
                    trueFalseSubjects: decodedTestData.partA?.trueFalseSubjects?.map(subject => ({
                        id: subject.id,
                        text: subject.text,
                        questions: subject.questions.map(question => ({
                            id: question.id,
                            text: question.text,
                            right_answer: question.answer, // Răspunsul corect
                            userAnswer: userAnswers.trueFalse[`partA-${subject.id}-${question.id}`] || null
                        }))
                    })),

                    // Cerințe text
                    requirementSubjects: decodedTestData.partA?.requirementSubjects?.map(subject => ({
                        id: subject.id,
                        text: subject.text,
                        right_answer: subject.correctAnswer, // Răspunsul corect dacă există
                        userAnswer: userAnswers.textAnswers[`partA-requirement-${subject.id}`] || ''
                    }))
                },

                partB: {
                    // Grid-uri
                    gridSubjects: decodedTestData.partB?.gridSubjects?.map(subject => ({
                        id: subject.id,
                        subpuncte: subject.subpuncte.map(subpunct => ({
                            id: subpunct.id,
                            text: subpunct.text,
                            right_answer: subpunct.answer,
                            userAnswer: userAnswers.grid[`partB-${subject.id}-${subpunct.id}`] || null
                        }))
                    })),

                    // Răspunsuri multiple
                    multiAnswers: decodedTestData.partB?.multiAnswers?.map(item => ({
                        id: item.id,
                        subpuncte: item.subpuncte.map(question => ({
                            id: question.id,
                            text: question.text,
                            answers: question.answers.map(answer => ({
                                id: answer.id,
                                right_answer: answer.words, // Răspunsurile corecte
                                userAnswer: userAnswers.multipleChoice[`partB-${item.id}-${question.id}-${answer.id}`] || ''
                            }))
                        }))
                    })),

                    // Cerințe text
                    requirementSubjects: decodedTestData.partB?.requirementSubjects?.map(subject => ({
                        id: subject.id,
                        text: subject.text,
                        right_answer: subject.correctAnswer, // Răspunsul corect dacă există
                        userAnswer: userAnswers.textAnswers[`partB-requirement-${subject.id}`] || ''
                    }))
                },

                // Cerințe text pentru Subiectul II
                requirementSubjects: decodedTestData.requirementSubjects?.map(subject => ({
                    id: subject.id,
                    text: subject.text,
                    right_answer: subject.correctAnswer, // Răspunsul corect dacă există
                    userAnswer: userAnswers.textAnswers[`subject2-requirement-${subject.id}`] || ''
                })),

                metadata: {
                    timestamp: new Date().toISOString(),
                    testVersion: decodedTestData.version || '1.0',
                    testId: decodedTestData.testId
                }
            };

            const result = await saveTestData({
                product_sku: productSku,
                test_type: 'nationalEvaluation',
                test_data: JSON.stringify(testDataToSave),
                test_score: null // Punctajul va fi calculat ulterior
            });

            if (result) {
                setSaveMessage('Răspunsurile au fost salvate cu succes!');
                setTestCompleted(true, { score: null });
            }
        } catch (error) {
            console.error('Eroare la salvarea răspunsurilor:', error);
            setSaveMessage(null);
        } finally {
            setIsSaving(false);
        }
    };

    if (!decodedTestData) return null;

    if (isTestCompleted && testResult !== null) {
        return (
            <EvNatTestResult score={0} saveMessage={'Inca nu putem sa iti afisam aici rezultatul'}/>
        );
    }

    return (
        <div
            className={'national-evaluation-container max-w-[1000px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 ' +
                'border-2 border-double border-medium-blue shadow-round-shadow rounded border-x border-y'}
        >
            <div className="flex flex-col items-start justify-start gap-3">
                <h2 className="uppercase font-font-p font-semibold text-text-color text-2xl">
                    Evaluare națională
                </h2>
            </div>
            <div
                className={'national-evaluation-container max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6'}>
                {/* Subiectul I - Texte inițiale */}
                <section className="mb-8">
                    <h2 className={'font-font-p font-semibold text-2xl text-text-color mb-3'}>
                        Subiectul I
                    </h2>
                    <p className="font-font-p font-semibold text-text-color text-xl mb-3">
                        Citeste cu atentie cele doua texte de mai jos, pentru a putea raspunde la cerintele formulate:
                    </p>
                    {decodedTestData.initialTexts?.map(textBlock => (
                        <div key={textBlock.id} className="mb-6">
                            {textBlock.texts.map(text => (
                                <div className={'mb-3 flex flex-col gap-3'}>
                                    <p className="font-font-p font-semibold text-text-color text-xl">
                                        Textul {text.id}
                                    </p>
                                    <div key={text.id}
                                         className={'font-font-s font-normal text-xl text-text-color bg-white p-3 border-2 ' +
                                             'border-double border-medium-blue rounded border-x border-y'}
                                         dangerouslySetInnerHTML={{__html: text.text}}
                                    />
                                </div>

                            ))}
                        </div>
                    ))}
                </section>
                {/* Partea A */}
                <section className="mb-8">
                    <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea A</h2>
                    {/* Multiple Answers Section */}
                    {decodedTestData.partA?.multiAnswers?.length > 0 && (
                        <div className="mb-8">
                            {decodedTestData.partA.multiAnswers.map((item, index) => (
                                <MultipleAnswerGroup
                                    key={item.id}
                                    item={item}
                                    index={index + 1}
                                    userAnswers={userAnswers}
                                    handleAnswerChange={handleAnswerChange}
                                    errors={errors}
                                    part="partA"
                                />
                            ))}
                        </div>
                    )}

                    {/* Grid Section */}
                    {decodedTestData.partA?.gridSubjects?.length > 0 && (
                        <div className="mb-8">
                            {decodedTestData.partA.gridSubjects.map((subject, index) => (
                                <GridQuestionGroup
                                    key={subject.id}
                                    subject={subject}
                                    index={index + 2}
                                    handleGridChange={(subjectId, subpunctId, answerId) =>
                                        handleGridChange(subjectId, subpunctId, answerId, 'partA')}
                                    userAnswers={userAnswers}
                                    errors={errors}
                                    part="partA"
                                />
                            ))}
                        </div>
                    )}

                    {/* True/False Section */}
                    {decodedTestData.partA?.trueFalseSubjects?.length > 0 && (
                        <div className="mb-8">
                            {decodedTestData.partA.trueFalseSubjects.map((subject, index) => {
                                const {firstGroup, secondGroup} = splitTrueFalseQuestions(subject.questions);
                                return (
                                    <div key={subject.id}
                                         className="border-2 border-double border-medium-blue rounded border-x border-y mb-4 p-3">
                                        <p className="text-2xl font-semibold text-text-color mb-3 italic">
                                            {index + 5}
                                        </p>
                                        <p className="font-font-s text-lg italic font-bold">
                                            Cerinta
                                        </p>
                                        <p
                                            className={'font-font-s font-normal text-xl text-text-color bg-white p-3 ' +
                                                'border-2 border-double border-medium-blue rounded border-x border-y mb-4'}
                                        >
                                            {'Bifeaza corectitudinea sau incorectitudinea fiecarui enunt, bazandu-te pe informatiile din cele doua texte.'}
                                        </p>

                                        <div className={'flex md:flex-row flex-col gap-3'}>
                                            <TrueFalseQuestionGroup
                                                subject={subject}
                                                questions={firstGroup}
                                                title="Textul 1"
                                                userAnswers={userAnswers}
                                                onAnswerChange={handleTrueFalseChange}
                                                errors={errors}
                                                part="partA"
                                            />
                                            <TrueFalseQuestionGroup
                                                subject={subject}
                                                questions={secondGroup}
                                                title="Textul 2"
                                                userAnswers={userAnswers}
                                                onAnswerChange={handleTrueFalseChange}
                                                errors={errors}
                                                part="partA"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {/* Requirement Subjects Section */}
                    {decodedTestData.partA?.requirementSubjects?.length > 0 && (
                        <div className={'flex flex-col gap-3'}>
                            {decodedTestData.partA.requirementSubjects.map((requirement, index) => (
                                <div key={requirement.id} className={''}>
                                    <p className="text-2xl font-semibold text-text-color mb-3 italic">
                                        {index + 6}
                                    </p>
                                    <div
                                        className="mb-4 border-2 border-double border-medium-blue rounded border-x border-y p-4"
                                    >
                                        <p className="font-font-s text-lg italic font-bold">
                                            Cerinta
                                        </p>
                                        <div
                                            dangerouslySetInnerHTML={{__html: requirement.text}}
                                            className={'font-font-s font-normal text-xl text-text-color bg-white p-3 ' +
                                                'border-2 border-double border-medium-blue rounded border-x border-y mb-4'}
                                        />
                                        <textarea
                                            name={`partA-requirement-${requirement.id}`}
                                            className="border-2 border-double border-medium-blue rounded border-x border-y w-full p-3"
                                            placeholder="Răspunsul tău..."
                                            rows={10}
                                            value={userAnswers.textAnswers[`partA-requirement-${requirement.id}`] || ''}
                                            onChange={(e) => handleRequirementChange(requirement.id, e.target.value, 'partA')}
                                        />
                                        {errors[`partA-requirement-${requirement.id}`] && (
                                            <p className="error-message text-red-500 font-font-s text-xl">
                                                {errors[`partA-requirement-${requirement.id}`]}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </section>

                {/* Partea B */}
                <section className="mb-8">
                    <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea B</h2>

                    {/* Grid Subjects Section */}
                    {decodedTestData.partB?.gridSubjects?.length > 0 && (
                        <div className="mb-8">
                            {decodedTestData.partB.gridSubjects.map((subject, index) => (
                                <GridQuestionGroup
                                    key={subject.id}
                                    subject={subject}
                                    index={index + 1}
                                    handleGridChange={(subjectId, subpunctId, answerId) =>
                                        handleGridChange(subjectId, subpunctId, answerId, 'partB')}
                                    userAnswers={userAnswers}
                                    errors={errors}
                                    part="partB"
                                />
                            ))}
                        </div>
                    )}

                    {/* Multiple Answers Section */}
                    {decodedTestData.partB?.multiAnswers?.length > 0 && (
                        <div className="mb-8">
                            {decodedTestData.partB.multiAnswers.map((item, index) => (
                                <MultipleAnswerGroup
                                    key={item.id}
                                    item={item}
                                    index={index + 5}
                                    userAnswers={userAnswers}
                                    handleAnswerChange={handleAnswerChange}
                                    errors={errors}
                                    part="partB"
                                />
                            ))}
                        </div>
                    )}

                    {/* Requirements Section */}
                    {decodedTestData.partB?.requirementSubjects?.length > 0 && (
                        <div className={'flex flex-col gap-3'}>
                            {decodedTestData.partB.requirementSubjects.map((requirement, index) => (
                                <div key={requirement.id} className={''}>
                                    <p className="text-2xl font-semibold text-text-color mb-3 italic">
                                        {index + 6}
                                    </p>
                                    <div
                                        className="mb-4 border-2 border-double border-medium-blue rounded border-x border-y p-4"
                                    >
                                        <p className="font-font-s text-lg italic font-bold">
                                            Cerinta
                                        </p>
                                        <div
                                            dangerouslySetInnerHTML={{__html: requirement.text}}
                                            className={'font-font-s font-normal text-xl text-text-color bg-white p-3 ' +
                                                'border-2 border-double border-medium-blue rounded border-x border-y mb-4'}
                                        />
                                        <textarea
                                            name={`partB-requirement-${requirement.id}`}
                                            className="border-2 border-double border-medium-blue rounded border-x border-y w-full p-3"
                                            placeholder="Răspunsul tău..."
                                            rows={10}
                                            value={userAnswers.textAnswers[`partB-requirement-${requirement.id}`] || ''}
                                            onChange={(e) => handleRequirementChange(requirement.id, e.target.value, 'partB')}
                                        />
                                        {errors[`partB-requirement-${requirement.id}`] && (
                                            <p className="error-message text-red-500 font-font-s text-xl">
                                                {errors[`partB-requirement-${requirement.id}`]}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </section>

                <section className="mb-8">
                    <h2 className={'font-font-p font-semibold text-2xl text-text-color mb-3'}>
                        Subiectul al II-lea
                    </h2>
                    {decodedTestData?.requirementSubjects?.length > 0 && (
                        <div
                            className={'flex flex-col gap-3'}>
                            {decodedTestData?.requirementSubjects?.length > 0 && (
                                <div className={'flex flex-col gap-3'}>
                                    {decodedTestData.requirementSubjects.map((requirement) => (
                                        <div key={requirement.id} className={''}>
                                            <div
                                                className="mb-4 border-2 border-double border-medium-blue rounded border-x border-y p-4"
                                            >
                                                <p className="font-font-s text-lg italic font-bold">
                                                    Cerinta
                                                </p>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: requirement.text}}
                                                    className={'font-font-s font-normal text-xl text-text-color bg-white p-3 ' +
                                                        'border-2 border-double border-medium-blue rounded border-x border-y mb-4'}
                                                />
                                                <textarea
                                                    name={`subject2-requirement-${requirement.id}`}
                                                    className="border-2 border-double border-medium-blue rounded border-x border-y w-full p-3"
                                                    placeholder="Răspunsul tău..."
                                                    rows={10}
                                                    value={userAnswers.textAnswers[`subject2-requirement-${requirement.id}`] || ''}
                                                    onChange={(e) => handleRequirementChange(requirement.id, e.target.value, 'subject2')}
                                                />
                                                {errors[`subject2-requirement-${requirement.id}`] && (
                                                    <p className="error-message text-red-500 font-font-s text-xl">
                                                        {errors[`subject2-requirement-${requirement.id}`]}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </section>

            </div>

            <div className="flex justify-center items-center flex-col">
                <SaveTestBtn
                    onClick={handleSubmit}
                    disabled={isSaving}
                    isSaving={isSaving}
                />
                {saveMessage && (
                    <p className="save-message mt-2 text-olive-color font-font-s text-xl">
                    {saveMessage}
                    </p>
                )}
                {mutationError && <ErrorMessage message={mutationError}/>}
            </div>
        </div>

    );
};
