import React from 'react';

export const TrueFalseQuestionGroup = ({subject, questions, title, userAnswers, onAnswerChange, errors, part}) => {
    if (questions.length === 0) return null;

    return (
        <div className="mb-4 md:w-1/2 w-full">
            <h3 className="font-font-s text-xl font-bold text-text-color mb-4">
                {title}
            </h3>
            <div className="space-y-4">
                {questions.map((question) => (
                    <div
                        key={question.id}
                        className={'p-4 border-2 border-double border-medium-blue rounded border-x border-y flex flex-col gap-3'}
                    >
                        <p className="font-font-s text-lg italic font-bold">
                            Întrebarea {question.id}
                        </p>
                        {question.text && (
                            <div
                                dangerouslySetInnerHTML={{__html: question.text}}
                                className={'font-font-s font-normal text-text-color text-xl bg-white p-3'}
                            />
                        )}
                        <div className="flex gap-4">
                            <label className="flex items-center gap-2 cursor-pointer">
                                <input
                                    type="radio"
                                    name={`tf-${part}-${subject.id}-${question.id}`}
                                    value="true"
                                    checked={userAnswers.trueFalse[`${part}-${subject.id}-${question.id}`] === true}
                                    onChange={() => onAnswerChange(subject.id, question.id, true, part)}
                                    className="w-4 h-4"
                                />
                                <span className="font-font-s text-lg">Adevărat</span>
                            </label>
                            <label className="flex items-center gap-2 cursor-pointer">
                                <input
                                    type="radio"
                                    name={`tf-${part}-${subject.id}-${question.id}`}
                                    value="false"
                                    checked={userAnswers.trueFalse[`${part}-${subject.id}-${question.id}`] === false}
                                    onChange={() => onAnswerChange(subject.id, question.id, false, part)}
                                    className="w-4 h-4"
                                />
                                <span className="font-font-s text-lg">Fals</span>
                            </label>
                        </div>
                        {errors[`tf-${part}-${subject.id}-${question.id}`] && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors[`tf-${part}-${subject.id}-${question.id}`]}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
