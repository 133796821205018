import React from 'react';

export const GridQuestionGroup = ({subject, index, handleGridChange, userAnswers, errors, part}) => {

    return (
        <div className={'grid-answer-item w-full flex flex-col items-start justify-start gap-5 w-full mb-4'}>
            <p className="text-2xl font-semibold text-text-color mb-3 italic">
                {index}
            </p>
            <div className={'border-2 border-double border-medium-blue rounded border-x border-y w-full p-3'}>
                {subject.subpuncte.map((subpunct) => (
                    <div key={subpunct.id}
                         className="subpunct w-full p-3 flex flex-col items-start justify-start gap-3"
                    >
                        <p className="font-font-s text-lg italic font-bold text-text-color mb-3">
                            Cerinta
                        </p>

                        <div
                            dangerouslySetInnerHTML={{__html: subpunct.text}}
                            className="font-font-s font-normal text-text-color text-xl text-text-color bg-white
                            p-3 border-2 border-double border-medium-blue rounded border-x border-y mb-3 w-full"
                        />
                        <p className="font-font-s text-lg italic font-bold text-text-color">
                            Raspunsurile:
                        </p>
                        <div
                            className="answers font-font-s font-normal text-text-color text-xl text-text-color flex flex-col gap-5">
                            {subpunct.answer.map((answer) => (
                                <label
                                    key={answer.id}
                                    className="w-full flex flex-row gap-1 items-center justify-start cursor-pointer"
                                    onClick={() => handleGridChange(subject.id, subpunct.id, answer.id)}
                                >
                                    <input
                                        type="radio"
                                        className="border-2 border-gray-300 p-2 w-[20px] rounded"
                                        name={`grid-${part}-${subject.id}-${subpunct.id}`}
                                        checked={userAnswers.grid[`${part}-${subject.id}-${subpunct.id}`] === answer.id}
                                        onChange={() => {
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                    <span className="font-font-s font-normal text-text-color text-xl">
                                        {answer.text}
                                    </span>
                                </label>
                            ))}
                        </div>
                        {errors[`grid-${subject.id}-${subpunct.id}`] && (
                            <p className="error-message text-red-500 font-font-s text-xl">
                                {errors[`grid-${subject.id}-${subpunct.id}`]}
                            </p>
                        )}
                    </div>
                ))}
            </div>

        </div>
    );
};
