import React, { useState, useEffect } from 'react';
import { useTestDataState } from '../../../../state';
import { QuillEditor } from "../assets/QuillEditor";

interface Question {
    id: number;
    text: string;
    answer: boolean | null;
}

interface TrueOrFalseData {
    product_id: number;
    product_sku?: string;
    trueOrFalse: {
        id: number;
        text: string;
        questions: Question[];
    }[];
}

const createInitialData = (productId: number): TrueOrFalseData => ({
    product_id: productId,
    trueOrFalse: [{
        id: 1,
        text: '',
        questions: Array(20).fill(null).map((_, index) => ({
            id: index + 1,
            text: '',
            answer: null
        }))
    }]
});

export const TrueOrFalse: React.FC<{ productId: number }> = ({ productId }) => {
    const { trueOrFalseData, handleSaveTrueOrFalse, loading, error } = useTestDataState();
    const [localData, setLocalData] = useState<TrueOrFalseData>(() => createInitialData(productId));

    useEffect(() => {
        if (trueOrFalseData?.trueOrFalse) {
            setLocalData(trueOrFalseData);
        } else if (trueOrFalseData?.product_id) {
            setLocalData(createInitialData(trueOrFalseData.product_id));
        }
    }, [trueOrFalseData]);

    const updateMainText = (content: string) => {
        setLocalData(prev => ({
            ...prev,
            trueOrFalse: [{
                ...prev.trueOrFalse[0],
                text: content
            }]
        }));
    };

    const updateQuestionText = (questionId: number, content: string) => {
        setLocalData(prev => ({
            ...prev,
            trueOrFalse: [{
                ...prev.trueOrFalse[0],
                questions: prev.trueOrFalse[0].questions.map(q =>
                    q.id === questionId ? { ...q, text: content } : q
                )
            }]
        }));
    };

    const updateAnswer = (questionId: number, value: boolean) => {
        setLocalData(prev => ({
            ...prev,
            trueOrFalse: [{
                ...prev.trueOrFalse[0],
                questions: prev.trueOrFalse[0].questions.map(q =>
                    q.id === questionId ? { ...q, answer: value } : q
                )
            }]
        }));
    };

    const handleSave = () => {
        if (localData) {
            handleSaveTrueOrFalse(localData, () => {
                window.location.reload();
            });
        }
    };

    if (loading) return <div>Se încarcă...</div>;
    if (error) return <div>Eroare: {error instanceof Error ? error.message : 'Eroare necunoscută'}</div>;

    const currentTest = localData.trueOrFalse[0];

    return (
        <div className="w-full">
            <h1 className="text-4xl font-bold text-amber-800 mb-5">
                Teste cu adevărat sau fals
            </h1>

            <div className="flex flex-col gap-8">
                <section>
                    <h2 className="text-2xl font-semibold mb-4">Text inițial</h2>
                    <QuillEditor
                        value={currentTest.text}
                        onEditorChange={updateMainText}
                        placeholder="Introduceți textul inițial"
                        className="bg-white rounded h-96 mb-20"
                    />
                </section>

                <section>
                    <h2 className="text-2xl font-semibold mb-4">Întrebări</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {currentTest.questions.map((question) => (
                            <div key={question.id} className="border-b border-gray-200 pb-8">
                                <h3 className="text-xl font-semibold mb-4">
                                    Întrebarea {question.id}
                                </h3>
                                <QuillEditor
                                    value={question.text}
                                    onEditorChange={(content) => updateQuestionText(question.id, content)}
                                    placeholder={`Introduceți textul pentru întrebarea ${question.id}`}
                                    className="bg-white rounded h-96 mb-20"
                                />
                                <div className="flex gap-6">
                                    <label className="flex items-center gap-2">
                                        <input
                                            type="radio"
                                            checked={question.answer === true}
                                            onChange={() => updateAnswer(question.id, true)}
                                            className="w-4 h-4"
                                        />
                                        <span>Adevărat</span>
                                    </label>
                                    <label className="flex items-center gap-2">
                                        <input
                                            type="radio"
                                            checked={question.answer === false}
                                            onChange={() => updateAnswer(question.id, false)}
                                            className="w-4 h-4"
                                        />
                                        <span>Fals</span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

            <div className="flex justify-end mt-8">
                <button
                    onClick={handleSave}
                    className="px-6 py-3 bg-amber-800 text-white rounded-lg hover:bg-amber-700 transition-colors"
                >
                    Salvează testul
                </button>
            </div>
        </div>
    );
};
