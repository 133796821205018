import React from 'react';
import {QuillEditor} from "./QuillEditor";

export const MultiAnswerSection = ({subject, part, onUpdateText, onUpdateAnswer}) => {
    return (
        <div className="bg-white rounded-lg p-6 shadow-sm mb-4">
            {subject.subpuncte.map(subpunct => (
                <div key={subpunct.id} className="mb-4">
                    <QuillEditor
                        value={subpunct.text}
                        onEditorChange={(content) => onUpdateText(part, subpunct.id, content)}
                        placeholder="Introduceți textul întrebării..."
                        className="bg-white rounded h-48 mb-20"
                    />
                    <div className="grid grid-cols-2 gap-4">
                        {subpunct.answers.map((ans, idx) => (
                            <div key={ans.id} className="space-y-2">
                                <input
                                    type="text"
                                    value={ans.words[0] || ''}
                                    onChange={(e) => onUpdateAnswer(part, subpunct.id, ans.id, e.target.value)}
                                    className="w-full p-2 border rounded"
                                    placeholder={`Introduceți răspunsul ${idx + 1}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
