import React from 'react';
import {TestFeedback} from './TestFeedback';

export const EvNatTestResult = ({score, saveMessage}) => {
    return (
        <div className="test-result flex flex-col items-center gap-6 p-6 max-w-2xl mx-auto mt-8 border-2 border-double border-medium-blue rounded">
            <h2 className="text-2xl font-semibold text-text-color">
                Test Completat
            </h2>
            <div className="text-center">
                <p className="text-lg mb-4">
                    Testul tău a fost înregistrat cu succes!
                </p>
                <p className="text-md text-gray-600">
                    Punctajul tau il regasesti in tabelul de istoric teste.
                </p>
            </div>
            {saveMessage && (
                <p className="text-olive-color font-font-s text-lg mt-4">
                    {saveMessage}
                </p>
            )}
        </div>
    );
};
