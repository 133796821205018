import React from 'react';
import {useHomepageContextProvider} from '../../state';
import {CmsBlocks} from './assets/CmsBlocks';
import {CategoryCards} from "./assets/CategoryCards";
import {LoadingIcon} from "../../../../general/assets/LoadingIcon";
import {CustomMarquee} from './assets/CustomMarquee';

export const HomeMainContent: React.FC = () => {
    const { categoriesData, loading, homepageData } = useHomepageContextProvider();

    if (loading || !homepageData) {
        return <LoadingIcon/>;
    }

    const { sections } = homepageData;

    const getSelectedCategories = (ids: string[]) => {
        if (!categoriesData || categoriesData.length === 0) {
            return [];
        }

        const findCategoriesRecursively = (categories: any[]): any[] => {
            let result: any[] = [];
            for (const category of categories) {
                if (ids.includes(category.id?.toString()) || ids.includes(category.id)) {
                    result.push(category);
                }
                if (category.children && category.children.length > 0) {
                    result = result.concat(findCategoriesRecursively(category.children));
                }
            }
            return result;
        };

        return findCategoriesRecursively(categoriesData);
    };

    return (
        <div className="homepage flex flex-col mt-5 xl:w-4/6">
            <CmsBlocks/>
            <div className="flex flex-col w-full">
                <div className="mb-2">
                    <CustomMarquee
                        text={sections.section1.text}
                        speed={50}
                        gradient={false}
                    />
                    <CategoryCards categories={getSelectedCategories(sections.section1.categories)}/>
                </div>
                <div className="mb-2">
                    <CustomMarquee
                        text={sections.section2.text}
                        speed={50}
                        gradient={false}
                    />
                    <CategoryCards categories={getSelectedCategories(sections.section2.categories)}/>
                </div>
                <div className="mb-2">
                    <CustomMarquee
                        text={sections.section3.text}
                        speed={50}
                        gradient={false}
                    />
                    <CategoryCards categories={getSelectedCategories(sections.section3.categories)}/>
                </div>
            </div>
        </div>
    );
};
