import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {
    saveTrueOrFalseData,
    getTrueOrFalseData,
    getProductAttributes,
    saveMultiChooseData,
    getMultiChooseData,
    getMultipleAnswerData,
    saveMultipleAnswerData,
    getTestCategories,
} from '../../queries';

interface Question {
    id: number;
    text: string;
    answer: boolean | null;
}

interface TrueOrFalseSubpunct {
    id: number;
    text: string;
    questions: Question[];
}

interface TrueOrFalseData {
    product_id: number;
    product_sku?: string;
    trueOrFalse: TrueOrFalseSubpunct[];
}

//Grid Test
interface MultiChooseAnswer {
    id: number;
    text: string;
    checked: boolean;
}

interface MultiChooseSubpunct {
    id: number;
    text: string;
    subpuncte: {
        id: number;
        text: string;
        answer: MultiChooseAnswer[];
    }[];
}

interface MultiChooseData {
    product_id: number;
    product_sku?: string;
    gridTestData: MultiChooseSubpunct[];
}

//MultipleAnswer
interface Answer {
    id: number;
    words: string[];
}

interface Subpunct {
    id: number;
    text: string;
    answers: [Answer, Answer];
}

interface MultipleAnswerSubpunct {
    id: number;
    text: string;
    subpuncte: Subpunct[];
}

interface MultipleAnswerData {
    product_id: number;
    product_sku?: string;
    multipleAnswer: MultipleAnswerSubpunct[];
}

//ProductData
interface ProductCategory {
    id: string;
    name: string;
    url_path: string;
}

interface ProductData {
    id: string;
    sku: string;
    name: string;
    categories: ProductCategory[];
}

//Test Category Selection
interface TestCategory {
    id: string;
    name: string;
}

interface TestCategories {
    trueFalse: TestCategory;
    multipleChoice: TestCategory;
    grid: TestCategory;
    nationalEvaluation: TestCategory;
}

interface TestDataState {
    trueOrFalseData: TrueOrFalseData | null;
    multiChooseData: MultiChooseData | null;
    multipleAnswerData: MultipleAnswerData | null;
    handleSaveTrueOrFalse: (trueOrFalseData: TrueOrFalseData, onSuccess?: () => void) => void;
    handleSaveMultiChoose: (multiChooseData: MultiChooseData, onSuccess?: () => void) => void;
    handleSaveMultipleAnswer: (multipleAnswerData: MultipleAnswerData, onSuccess?: () => void) => void;
    productData: ProductData | null;
    testCategory: TestCategories | null;
    categories: ProductCategory[] | null;
    loading: boolean;
    error: Error | unknown;
}

const TestDataContext = createContext<TestDataState  | undefined>(undefined);

export const TestDataProvider: React.FC<{ children: React.ReactNode, productId: number, sku: string }> = (
    {
        children,
        productId,
        sku
    }) => {

    const [trueOrFalseData, setTrueOrFalseData] = useState<TrueOrFalseData | null>(null);
    const [productData, setProductData] = useState<ProductData | null>(null);
    const [multiChooseData, setMultiChooseData] = useState<MultiChooseData | null>(null);
    const [multipleAnswerData, setMultipleAnswerData] = useState<MultipleAnswerData | null>(null);
    const [testCategory, setTestCategory] = useState<TestCategories | null>(null);
    const categories = productData?.categories || null;

    const trueOrFalseMutation = useMutation(
        (variables: { input: TrueOrFalseData }) => request(saveTrueOrFalseData, variables),
        {
            onSuccess: (data) => {
                setTrueOrFalseData(data.saveTrueOrFalseData);
            },
        }
    );

    const multiChooseMutation = useMutation(
        (variables: { input: MultiChooseData }) => request(saveMultiChooseData, variables),
        {
            onSuccess: (data) => {
                setMultiChooseData(data.saveMultiChooseData);
            },
        }
    );

    const multipleAnswerMutation = useMutation(
        (variables: { input: MultipleAnswerData }) => request(saveMultipleAnswerData, variables),
        {
            onSuccess: (data) => {
                setMultipleAnswerData(data.saveMultipleAnswerData);
            },
        }
    );

    const handleSaveMultiChoose = (multiChooseData: MultiChooseData, onSuccess?: () => void) => {
        multiChooseMutation.mutate({
            input: {
                product_id: productId,
                gridTestData: multiChooseData.gridTestData
            }
        }, {
            onSuccess: (data) => {
                setMultiChooseData(data.saveMultiChooseData);
                if (onSuccess) {
                    onSuccess();
                }
            },
        });
    };

    const handleSaveTrueOrFalse = (trueOrFalseData: TrueOrFalseData, onSuccess?: () => void) => {
        trueOrFalseMutation.mutate({
            input: {
                product_id: productId,
                trueOrFalse: trueOrFalseData.trueOrFalse
            }
        }, {
            onSuccess: (data) => {
                setTrueOrFalseData(data.saveTrueOrFalseData);
                if (onSuccess) {
                    onSuccess();
                }
            },
        });
    };

    const handleSaveMultipleAnswer = (multipleAnswerData: MultipleAnswerData, onSuccess?: () => void) => {
        multipleAnswerMutation.mutate({
            input: {
                product_id: productId,
                multipleAnswer: multipleAnswerData.multipleAnswer
            }
        }, {
            onSuccess: (data) => {
                setMultipleAnswerData(data.saveMultipleAnswerData);
                if (onSuccess) {
                    onSuccess();
                }
            },
        });
    };

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes', sku],
        queryFn: async () => {
            return request(getProductAttributes, { sku});
        },
        enabled: !!sku,
        refetchOnWindowFocus: false,
    });

    const testCategorySelectionQuery = useQuery({
        queryKey: ['testCategory', productId],
        queryFn: async () => {
            const result = await request(getTestCategories);
            return result.data?.testCategories ?? null;
        },
        enabled: !!sku,
        refetchOnWindowFocus: false,
    });

    const trueOrFalseQuery = useQuery({
        queryKey: ['trueOrFalseData', productId],
        queryFn: async () => {
            const result = await request(getTrueOrFalseData, {product_id: productId});
            return result.data?.getTrueOrFalseData ?? null;
        },
        enabled: !!productId,
        refetchOnWindowFocus: false,
    });

    const multiChooseQuery = useQuery({
        queryKey: ['gridTestData', productId],
        queryFn: async () => {
            const result = await request(getMultiChooseData, {product_id: productId});
            return result.data?.getMultiChooseData ?? null;
        },
        enabled: !!productId,
        refetchOnWindowFocus: false,
    });

    const multipleAnswerQuery = useQuery({
        queryKey: ['multipleAnswerData', productId],
        queryFn: async () => {
            const result = await request(getMultipleAnswerData, {product_id: productId});
            return result.data?.getMultipleAnswerData ?? null;
        },
        enabled: !!productId,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (testCategorySelectionQuery.data) {
            setTestCategory(testCategorySelectionQuery?.data);
        }

        if (trueOrFalseQuery?.data) {
            setTrueOrFalseData(trueOrFalseQuery?.data);
        }

        if (multiChooseQuery.data) {
            setMultiChooseData(multiChooseQuery?.data);
        }

        if (multipleAnswerQuery.data) {
            setMultipleAnswerData(multipleAnswerQuery.data);
        }

        if (productAttributesQuery.data) {
            setProductData(productAttributesQuery?.data?.data?.products.items[0]);
        }

    }, [
        trueOrFalseQuery.data,
        productAttributesQuery.data,
        multiChooseQuery.data,
        multipleAnswerQuery.data,
        testCategorySelectionQuery.data
    ]);

    return (
        <TestDataContext.Provider value={{
            trueOrFalseData,
            multiChooseData,
            multipleAnswerData,
            handleSaveTrueOrFalse,
            handleSaveMultiChoose,
            handleSaveMultipleAnswer,
            productData,
            testCategory,
            categories,
            error: trueOrFalseMutation.error || trueOrFalseQuery.error || multiChooseMutation.error || multipleAnswerMutation.error || testCategorySelectionQuery.error || productAttributesQuery.error,
            loading: trueOrFalseQuery.isLoading || trueOrFalseMutation.isLoading || multiChooseQuery.isLoading || multipleAnswerQuery.isLoading || testCategorySelectionQuery.isLoading || productAttributesQuery.isLoading,
        }}>
            {children}
        </TestDataContext.Provider>
    );
};

export const useTestDataState = () => {
    const context = useContext(TestDataContext);
    if (context === undefined) {
        throw new Error("useTestDataState must be used within a TestDataProvider");
    }
    return context;
};
