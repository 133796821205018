import React, {useState, useEffect} from 'react';
import {useHomepageContextProvider} from '../../../state';
import {useWindowSize} from '../../../../../general/useWindowSize';
import {LoadingIcon} from '../../../../../general/assets/LoadingIcon';
import {ImageSlider} from './ImageSlider';
import {CustomMarquee} from "./CustomMarquee";

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function extractImageData(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const figures = doc.querySelectorAll('figure');

    return Array.from(figures).map(figure => {
        const link = figure.querySelector('a');
        const img = figure.querySelector('img.pagebuilder-mobile-hidden');
        return {
            src: img?.getAttribute('src') || '',
            alt: img?.getAttribute('alt') || '',
            title: link?.getAttribute('title') || '',
            link: link?.getAttribute('href') || '#'
        };
    });
}

export const CmsBlocks: React.FC = () => {
    const {blockData1, blockData2, blockData3, blockData4, loading} = useHomepageContextProvider();
    const {isMobile, isTablet} = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const [buttonStyles, setButtonStyles] = useState([]);

    const getRandomPosition = (index: number) => {
        const third = 100 / 3;
        const left = `${index * third + Math.random() * (third - 20)}%`;
        const top = `${Math.random() * 60}%`;
        return {top, left};
    };

    const getRandomRotation = () => `rotate(${Math.random() * 30 - 15}deg)`;

    const tabs = [
        {name: blockData2?.title, content: blockData2},
        {name: blockData3?.title, content: blockData3},
        {name: blockData4?.title, content: blockData4},
    ];

    useEffect(() => {
        setButtonStyles(tabs.map((_, index) => ({
            ...getRandomPosition(index),
            transform: getRandomRotation(),
        })));
    }, []);

    const renderBlockContent = (blockData: any) => {
        if (!blockData || !blockData.content) return null;
        return <div dangerouslySetInnerHTML={{__html: decodeHtml(blockData.content)}}></div>;
    };

    if (loading) {
        return <LoadingIcon/>;
    }

    const isSmallScreen = isMobile || isTablet;

    // Procesăm datele pentru ImageSlider
    const slides = blockData1?.content ? extractImageData(decodeHtml(blockData1.content)) : [];

    return (
        <div className="mb-12 w-full flex flex-col items-center">
            <div className="w-full">
                <div className="homepage-block1 mb-5">
                    <ImageSlider slides={slides}/>
                </div>
                <div className="homepage-info-buttons flex flex-col items-center justify-center mb-4">
                    <CustomMarquee text="What is all about!"/>
                    <div
                        className={`w-full ${isSmallScreen ? 'flex flex-col items-center space-y-4' : 'relative h-[300px]'} md:w-1/2 py-5`}>
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                style={isSmallScreen ? {} : buttonStyles[index]}
                                className={`${isSmallScreen ? 'w-[60%]' : 'absolute'} h-auto md:min-h-[40%] px-4 py-2
                                w-52 font-font-p text-2xl font-bold transition-all duration-300 ease-in-out rounded-xl
                                ${activeTab === index
                                    ? 'bg-wood-color text-white px-6 py-2 rounded-xl disable:bg-olive-color' +
                                    'hover:bg-olive-color hover:text-white font-font-p font-bold ' +
                                    'hover:shadow-custom-glow transition duration-300 ease-in-out ' +
                                    'focus:bg-olive-color active:bg-olive-color focus:text-white active:text-white'
                                    : 'bg-wood-color text-peru-color hover:bg-wood-color hover:text-white shadow-md'
                                }`}
                                onClick={() => setActiveTab(index)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="homepage-block-content text-text-color font-font-s text-xl min-h-[400px]">
                    {renderBlockContent(tabs[activeTab].content)}
                </div>
            </div>
        </div>
    );
};
