import React from "react";

export const TrueFalseDisplay = ({question, score}) => {
    return (
        <div className="mt-4 bg-gray-50 p-4 rounded">
            <div className="flex items-start gap-2">
                <span className="font-semibold text-gray-700">Punctaj acordat:</span>
                <span className="text-blue-600 font-medium">
                        {score ? score.toFixed(2) : '0'} puncte
                    </span>
            </div>
            <div className="flex items-start gap-2">
                <span className="font-semibold text-gray-700">Răspunsul tău:</span>
                <span
                    className={`${question.userAnswer === question.right_answer ? 'text-green-600' : 'text-red-600'}`}>
                    {question.userAnswer ? 'Adevărat' : 'Fals'}
                </span>
            </div>
            <div className="flex items-start gap-2">
                <span className="font-semibold text-gray-700">Răspuns corect:</span>
                <span className="text-green-600">{question.right_answer ? 'Adevărat' : 'Fals'}</span>
            </div>
        </div>
    );
};
