import React, { useState, useEffect } from 'react';
import { useTestDataState } from '../../../../state';
import { QuillEditor } from "../assets/QuillEditor";

interface Answer {
    id: number;
    text: string;
    checked: boolean;
}

interface GridTest {
    id: number;
    text: string;
    subpuncte: {
        id: number;
        text: string;
        answer: Answer[];
    }[];
}

interface GridTestData {
    product_id: number;
    product_sku?: string;
    gridTestData: GridTest[];
}

const createInitialData = (productId: number): GridTestData => ({
    product_id: productId,
    gridTestData: [{
        id: 1,
        text: '',
        subpuncte: Array(20).fill(null).map((_, index) => ({
            id: index + 1,
            text: '',
            answer: Array(4).fill(null).map((_, idx) => ({
                id: idx + 1,
                text: '',
                checked: false
            }))
        }))
    }]
});

export const MultiChoose: React.FC<{ productId: number }> = ({ productId }) => {
    const { multiChooseData, handleSaveMultiChoose, loading, error } = useTestDataState();
    const [localData, setLocalData] = useState<GridTestData>(() => createInitialData(productId));

    useEffect(() => {
        if (multiChooseData?.gridTestData) {
            setLocalData(multiChooseData);
        } else if (multiChooseData?.product_id) {
            setLocalData(createInitialData(multiChooseData.product_id));
        }
    }, [multiChooseData]);

    const updateMainText = (content: string) => {
        setLocalData(prev => ({
            ...prev,
            gridTestData: [{
                ...prev.gridTestData[0],
                text: content
            }]
        }));
    };

    const updateQuestionText = (questionId: number, content: string) => {
        setLocalData(prev => ({
            ...prev,
            gridTestData: [{
                ...prev.gridTestData[0],
                subpuncte: prev.gridTestData[0].subpuncte.map(sp =>
                    sp.id === questionId ? { ...sp, text: content } : sp
                )
            }]
        }));
    };

    const updateAnswer = (questionId: number, answerId: number, field: 'text' | 'checked', value: string | boolean) => {
        setLocalData(prev => ({
            ...prev,
            gridTestData: [{
                ...prev.gridTestData[0],
                subpuncte: prev.gridTestData[0].subpuncte.map(sp =>
                    sp.id === questionId ? {
                        ...sp,
                        answer: sp.answer.map(ans =>
                            ans.id === answerId ? { ...ans, [field]: value } : ans
                        )
                    } : sp
                )
            }]
        }));
    };

    const handleSave = () => {
        if (localData) {
            handleSaveMultiChoose(localData, () => {
                window.location.reload();
            });
        }
    };

    if (loading) return <div>Se încarcă...</div>;
    if (error) return <div>Eroare: {error instanceof Error ? error.message : 'Eroare necunoscută'}</div>;

    const currentTest = localData.gridTestData[0];

    return (
        <div className="w-full">
            <h1 className="text-4xl font-bold text-amber-800 mb-5">
                Test Grilă
            </h1>

            <div className="flex flex-col gap-8">
                <section>
                    <h2 className="text-2xl font-semibold mb-4">Text inițial</h2>
                    <div className="bg-white rounded mb-8">
                        <QuillEditor
                            value={currentTest.text}
                            onEditorChange={updateMainText}
                            placeholder="Introduceți textul inițial"
                            className="bg-white rounded h-96 mb-20"
                        />
                    </div>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold mb-4">Întrebări și răspunsuri</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {currentTest.subpuncte.map((subpunct) => (
                            <div key={subpunct.id} className="border-b border-gray-200 pb-8">
                                <h3 className="text-xl font-semibold mb-4">
                                    Întrebarea {subpunct.id}
                                </h3>
                                <div className="bg-white rounded mb-4">
                                    <QuillEditor
                                        value={subpunct.text}
                                        onEditorChange={(content) => updateQuestionText(subpunct.id, content)}
                                        placeholder={`Introduceți textul pentru întrebarea ${subpunct.id}`}
                                        className="bg-white rounded h-96 mb-20"
                                    />
                                </div>
                                <div className="space-y-2">
                                    {subpunct.answer.map((answer) => (
                                        <div key={answer.id} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                checked={answer.checked}
                                                onChange={(e) => updateAnswer(subpunct.id, answer.id, 'checked', e.target.checked)}
                                                className="w-4 h-4"
                                            />
                                            <input
                                                type="text"
                                                value={answer.text}
                                                onChange={(e) => updateAnswer(subpunct.id, answer.id, 'text', e.target.value)}
                                                placeholder={`Răspuns ${answer.id}`}
                                                className="flex-1 border rounded p-2"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

            <div className="flex justify-end mt-8">
                <button
                    onClick={handleSave}
                    className="px-6 py-3 bg-amber-800 text-white rounded-lg hover:bg-amber-700 transition-colors"
                >
                    Salvează testul
                </button>
            </div>
        </div>
    );
};
