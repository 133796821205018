import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {
    saveNationalEvaluationData,
    getNationalEvaluationData,
} from '../../queries';

// Add National Evaluation interfaces
interface InitialText {
    id: number;
    text: string;
}

interface Answer {
    id: number;
    words: string[];
}

interface GridAnswer {
    id: number;
    text: string;
    checked: boolean;
}

interface multiAnswersSubject {
    id: number;
    subpuncte: {
        id: number;
        text: string;
        answers: [Answer, Answer];
    }[];
}

interface GridSubject {
    id: number;
    subpuncte: {
        id: number;
        text: string;
        answer: GridAnswer[];
    }[];
}

interface TrueFalseQuestion {
    id: number;
    text: string;
    answer: boolean | null;
}

interface TrueFalseSubject {
    id: number;
    questions: TrueFalseQuestion[];
}

interface RequirementSubject {
    id: number;
    text: string;
}

interface PartA {
    multiAnswers: multiAnswersSubject[];
    gridSubjects: GridSubject[];
    trueFalseSubjects: TrueFalseSubject[];
    requirementSubjects: RequirementSubject[];
}

interface PartB {
    gridSubjects: GridSubject[];
    multiAnswers: multiAnswersSubject[];
    requirementSubjects: RequirementSubject[];
}

interface NationalEvaluationContent {
    initialTexts: {
        id: number;
        texts: InitialText[];
    }[];
    partA: PartA;
    partB: PartB;
    requirementSubjects: RequirementSubject[];
}

interface NationalEvaluationData {
    product_id: number;
    product_sku?: string;
    nationalEvaluation: NationalEvaluationContent[];
}

interface TestDataState {
    evaluationData: NationalEvaluationData | null;
    handleSaveEvaluation: (evaluationData: NationalEvaluationData, onSuccess?: () => void) => void;
    loading: boolean;
    error: Error | unknown;
}

const TestDataEvaluationContext = createContext<TestDataState | undefined>(undefined);

export const EvaluationProvider: React.FC<{ children: React.ReactNode; productId: number }> = (
    {
        children,
        productId,
    }) => {

    const [evaluationData, setEvaluationData] = useState<NationalEvaluationData | null>(null);

    const evaluationMutation = useMutation(
        (variables: { input: NationalEvaluationData }) => request(saveNationalEvaluationData, variables),
        {
            onSuccess: (data) => {
                setEvaluationData(data.saveNationalEvaluationData);
            },
        }
    );

    const handleSaveEvaluation = (evaluationData: NationalEvaluationData, onSuccess?: () => void) => {
        evaluationMutation.mutate({
            input: {
                product_id: productId,
                nationalEvaluation: evaluationData.nationalEvaluation
            }
        }, {
            onSuccess: (data) => {
                setEvaluationData(data.saveNationalEvaluationData);
                onSuccess?.();
            },
        });
    };

    const evaluationQuery = useQuery({
        queryKey: ['evaluationData', productId],
        queryFn: async () => {
            const result = await request(getNationalEvaluationData, { product_id: productId });
            return result.data?.getNationalEvaluationData ?? null;
        },
        enabled: Boolean(productId),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (evaluationQuery.data) {
            setEvaluationData(evaluationQuery.data);
        }
    }, [evaluationQuery.data]);


    return (
        <TestDataEvaluationContext.Provider value={{
            evaluationData,
            handleSaveEvaluation,
            error: evaluationMutation.error || evaluationQuery.error,
            loading: evaluationQuery.isLoading || evaluationMutation.isLoading,
        }}>
            {children}
        </TestDataEvaluationContext.Provider>
    );
};

export const useEvaluationState = () => {
    const context = useContext(TestDataEvaluationContext);
    if (context === undefined) {
        throw new Error("useEvaluationState must be used within a EvaluationProvider");
    }
    return context;
};
