import React from 'react';

export const MultipleAnswerGroup = ({item, userAnswers, handleAnswerChange, errors, index, part}) => {
    return (
        <div className={'multiple-answer-item w-full flex flex-col items-start justify-start gap-5 w-full'}>
            <p className="text-2xl font-semibold text-text-color mb-4 italic">
                {index}
            </p>
            {item.subpuncte.map((subpunct) => (
                <div
                    key={subpunct.id}
                    className="question w-full p-3 flex flex-col items-start justify-start gap-3 border-2 border-double border-medium-blue rounded border-x border-y"
                >
                    <p className="font-font-s text-lg italic font-bold">
                        Cerinta
                    </p>
                    {subpunct.text && (
                        <p
                            dangerouslySetInnerHTML={{__html: subpunct.text}}
                            className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2 border-double border-medium-blue rounded border-x border-y"
                        />
                    )}
                    <p className="font-font-s text-lg italic font-bold">
                        Răspunsurile:
                    </p>
                    <div
                        className="answers font-font-s font-normal text-text-color text-xl flex flex-col md:flex-row gap-5 w-full">
                        {subpunct.answers.map(answer => (
                            <div key={answer.id} className="w-full md:w-1/3">
                                <input
                                    className="border-2 border-gray-300 p-2 w-full rounded-xl"
                                    type="text"
                                    value={userAnswers.multipleChoice[`${part}-${item.id}-${subpunct.id}-${answer.id}`] || ''}
                                    onChange={(e) => handleAnswerChange(item.id, subpunct.id, answer.id, e.target.value, part)}
                                    placeholder={`Răspuns ${answer.id}`}
                                />
                                {errors[`${part}-${item.id}-${subpunct.id}-${answer.id}`] && (
                                    <p className="error-message text-red-500 font-font-s text-xl">
                                        {errors[`${part}-${item.id}-${subpunct.id}-${answer.id}`]}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
