import React from 'react';
import {EvaluationProvider, TestDataProvider} from '../../state';
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {TestSelector} from "./RoTests/TestSelector";

const queryClient = client();

export const TestWrapper: React.FC<{ productId: number, productSku: string }> = ({productId, productSku}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <EvaluationProvider productId={productId}>
                <TestDataProvider productId={productId} sku={productSku}>
                    <TestSelector productId={productId}/>
                </TestDataProvider>
            </EvaluationProvider>
        </QueryClientProvider>
    );
};
