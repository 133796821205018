import React, {useState} from 'react';
import {useCustomerTestData} from '../../../state';
import {TestDetailsView} from './TestDetailsView';

interface CustomerTestData {
    entity_id: number;
    product_sku: string;
    test_type: string;
    test_increment: number;
    test_code: string;
    test_data: string;
    class_lvl_code: string;
    module_lvl_code: string;
    difficulty_lvl_code: string;
    test_score: number;
    created_at: string;
    displayIndex?: number;
}

export const CustomerTestTable: React.FC = () => {
    const {
        customerData,
        loading,
        error,
        currentPage,
        setCurrentPage,
        totalPages,
        paginatedTestData
    } = useCustomerTestData();

    const [selectedTest, setSelectedTest] = useState<CustomerTestData | null>(null);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!customerData) return <p>No data available</p>;

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleViewTest = (test: CustomerTestData) => {
        setSelectedTest(test);
    };

    const handleBack = () => {
        setSelectedTest(null);
    };

    if (selectedTest) {
        return <TestDetailsView testData={selectedTest} onBack={handleBack}/>;
    }

    return (
        <div className="max-w-6xl p-4">
            <h2 className="text-2xl font-bold font-font-p mb-2">Customer: {customerData.firstname} {customerData.lastname}</h2>
            <p className="mb-4 font-font-p ">Email: {customerData.email}</p>
            <div className="overflow-x-auto">
                <table className="w-full border-collapse border border-[#8B4513] rounded-lg overflow-hidden font-font-s">
                    <thead>
                    <tr className="bg-[#8B4513] text-white">
                        <th className="py-2 px-4 border border-wood-color font-font-s">Nr.</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Cod Test</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Clasa</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Modul</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Dificultate</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Data</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Nota</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedTestData.map((test) => (
                        <tr key={test.entity_id}
                            className={test.displayIndex && test.displayIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                        <td className="py-2 px-4 border border-wood-color font-font-s">{test.displayIndex}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p">{test.test_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p">{test.class_lvl_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p">{test.module_lvl_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p">{test.difficulty_lvl_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-s">{new Date(test.created_at).toLocaleDateString()}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p font-bold">{test.test_score}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-s">
                                <button
                                    onClick={() => handleViewTest(test)}
                                    className={'px-4 py-2 bg-wood-color text-white rounded disabled:opacity-50 font-font-p ' +
                                        'hover:bg-olive-color hover:text-white active:bg-olive-color active:text-white focus:bg-olive-color focus:text-white'}
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="w-full flex flex-row items-center justify-center gap-4 mt-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={'px-4 py-2 bg-wood-color text-white rounded disabled:opacity-50 font-font-p ' +
                        'hover:bg-olive-color hover:text-white active:bg-olive-color active:text-white focus:bg-olive-color focus:text-white'}
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={'px-4 py-2 bg-wood-color text-white rounded disabled:opacity-50 font-font-p ' +
                        'hover:bg-olive-color hover:text-white active:bg-olive-color active:text-white focus:bg-olive-color focus:text-white'}
                >
                    Next
                </button>
            </div>
        </div>
    );
};
