import React from 'react';

export const MultipleAnswerDisplay = ({subpunct, score}) => {
    return (
        <>
            {subpunct.answers.map((answer, index) => (
                <div key={index} className="mt-4 bg-gray-50 rounded">
                    <div className="flex items-start gap-2">
                        <span className="font-semibold text-gray-700">Punctaj acordat:</span>
                        <span className="text-blue-600 font-medium">
                            {score ? score.toFixed(2) : '0'} puncte
                        </span>
                    </div>
                    <div className="flex items-start gap-2">
                        <span className="font-semibold text-gray-700">Răspunsul tău {index + 1}:</span>
                        <span className={`${answer.score > 0 ? 'text-green-600' : 'text-red-600'}`}>
                          {answer.userAnswer}
                        </span>
                    </div>
                    <div className="flex items-start gap-2">
                        <span className="font-semibold text-gray-700">Răspunsuri acceptate:</span>
                        <span className="text-green-600">{answer.right_answer.join(' sau ')}</span>
                    </div>
                </div>

            ))}
        </>
    );
};
