import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface QuillEditorProps {
    value: string;
    onEditorChange?: (content: string) => void;
    placeholder?: string;
    className?: string;
}

const modules = {
    toolbar: [
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'align': []}],
        ['link'],
        ['clean'],
        [{'color': []}, {'background': []}],
        [{'font': []}],
    ]
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'align',
    'link',
    'color', 'background',
    'font'
];

export const QuillEditor: React.FC<QuillEditorProps> = (
    {
        value,
        onEditorChange,
        placeholder,
        className
    }) => {

    return (
        <ReactQuill
            theme="snow"
            value={value || ''}
            onChange={onEditorChange}
            modules={modules}
            formats={formats}
            placeholder={placeholder}
            className={className}
        />
    );
};
