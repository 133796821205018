import React, { useState, useMemo } from 'react';
import { useProductAttributes } from '../../../../state';
import { TestResult } from "../assets/TestResult";
import { SaveTestBtn, ErrorMessage } from "../../../../../../general";

interface Answer {
    checked: boolean;
    id: number;
    text: string;
}

interface MultiChooseSubpunct {
    answer: Answer[];
    id: number;
    text: string;
}

interface MultiChooseData {
    id: number;
    subpuncte: MultiChooseSubpunct[];
    text: string;
}

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const MultiChoose = () => {
    const {
        testData,
        saveTestData,
        productSku,
        mutationError,
        clearMutationError,
        isTestCompleted,
        testResult,
        setTestCompleted
    } = useProductAttributes() || {};

    const [userAnswers, setUserAnswers] = useState<{ [key: string]: number }>({});
    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // Decode and initialize test data
    const decodedTestData = useMemo(() => {
        if (!testData || !testData.gridTestData) return null;

        return {
            ...testData,
            gridTestData: testData.gridTestData.map(item => ({
                ...item,
                text: decodeHtml(item.text),
                subpuncte: item.subpuncte.map(subpunct => ({
                    ...subpunct,
                    text: decodeHtml(subpunct.text),
                    answer: subpunct.answer.map(answer => ({
                        ...answer,
                        text: decodeHtml(answer.text)
                    }))
                }))
            }))
        };
    }, [testData]);

    // Initialize user answers once when the component renders
    const initializeAnswers = () => {
        const initialAnswers = {};
        decodedTestData.gridTestData.forEach(item => {
            item.subpuncte.forEach(subpunct => {
                initialAnswers[`${item.id}-${subpunct.id}`] = -1;
            });
        });
        setUserAnswers(initialAnswers);
    };

    // Only initialize once, avoiding `useEffect` with local storage
    if (!Object.keys(userAnswers).length && decodedTestData?.gridTestData) {
        initializeAnswers();
    }

    const handleRadioChange = (itemId: number, subpunctId: number, answerId: number) => {
        const key = `${itemId}-${subpunctId}`;
        setUserAnswers(prev => ({
            ...prev,
            [key]: answerId
        }));

        if (errors[key]) {
            setErrors(prev => {
                const newErrors = { ...prev };
                delete newErrors[key];
                return newErrors;
            });
        }
    };

    const handleSave = async () => {
        const newErrors = {};
        decodedTestData.gridTestData.forEach(item => {
            item.subpuncte.forEach(subpunct => {
                const key = `${item.id}-${subpunct.id}`;
                if (userAnswers[key] === -1) {
                    newErrors[key] = 'Selectează un răspuns';
                }
            });
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            setSaveMessage('Te rugăm să răspunzi la toate întrebările înainte de a finaliza testul.');
            return;
        }

        setIsSaving(true);
        clearMutationError();

        try {
            const testType = 'grid_data';

            const userTestData = decodedTestData.gridTestData.map(item => ({
                ...item,
                subpuncte: item.subpuncte.map(subpunct => ({
                    ...subpunct,
                    user_answer: userAnswers[`${item.id}-${subpunct.id}`],
                    right_answer: subpunct.answer.find(a => a.checked)?.id
                }))
            }));

            const testDataToSave = {
                gridTestData: userTestData,
                timestamp: new Date().toISOString()
            };

            const score = calculateScore(userAnswers, decodedTestData.gridTestData);

            const result = await saveTestData({
                product_sku: productSku,
                test_type: testType,
                test_data: JSON.stringify(testDataToSave),
                test_score: score
            });

            if (result) {
                setSaveMessage('Testul a fost salvat!');
                setTestCompleted(true, { score: score });
            } else {
                setSaveMessage(null);
            }
        } catch (error) {
            console.error('Eroare la salvarea răspunsurilor:', error);
            setSaveMessage(null);
        } finally {
            setIsSaving(false);
        }
    };

    const calculateScore = (userAnswers: { [key: string]: number }, questions: MultiChooseData[]): number => {
        let correctAnswers = 0;
        let totalQuestions = 0;

        questions.forEach(item => {
            item.subpuncte.forEach(subpunct => {
                const key = `${item.id}-${subpunct.id}`;
                const correctAnswerId = subpunct.answer.find(a => a.checked)?.id;
                if (userAnswers[key] === correctAnswerId) {
                    correctAnswers++;
                }
                totalQuestions++;
            });
        });

        return Number(((correctAnswers / totalQuestions) * 100).toFixed(2));
    };

    if (!decodedTestData || !decodedTestData.gridTestData || decodedTestData.gridTestData.length === 0) {
        return null;
    }

    if (isTestCompleted && testResult) {
        return (
            <TestResult
                score={testResult.score}
                saveMessage={saveMessage}
            />
        );
    }

    return (
        <div className="multi-choose-container max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border-2 border-double border-medium-blue
            shadow-round-shadow rounded border-x border-y"
        >
            <div className="flex flex-col items-start justify-start gap-3">
                <h2 className="uppercase font-font-p font-semibold text-text-color text-2xl text-text-color">
                    Test grila
                </h2>
                <p className="font-font-p font-semibold text-text-color text-xl text-text-color">
                    Citeste cu atentie testul de mai jos, pentru a putea raspunde la cerintele formulate:
                </p>
            </div>
            {decodedTestData.gridTestData.map((item: MultiChooseData) => (
                <div key={item.id} className="multi-choose-item w-full mb-6 flex flex-col items-start justify-start gap-5">
                    <div dangerouslySetInnerHTML={{ __html: item.text }}
                         className="font-font-s font-normal text-xl text-text-color bg-white
                         p-3 border-2 border-double border-medium-blue rounded border-x border-y"
                    />
                    <p className="font-font-p font-semibold text-text-color text-xl text-text-color">
                        Cerinte:
                    </p>
                    {item.subpuncte.map((subpunct) => (
                        <div key={subpunct.id} className="subpunct w-full p-3 flex flex-col items-start justify-start gap-3
                              border-2 border-double border-medium-blue rounded border-x border-y">
                            <p className="font-font-s text-lg italic font-bold text-text-color">
                                Intrebarea {subpunct.id}
                            </p>
                            {subpunct.text && (
                                <p dangerouslySetInnerHTML={{ __html: subpunct.text }}
                                   className="font-font-s font-normal text-text-color text-xl text-text-color bg-white
                                   p-3 border-2 border-double border-medium-blue rounded border-x border-y"
                                />
                            )}
                            <p className="font-font-s text-lg italic font-bold text-text-color">
                                Raspunsurile:
                            </p>
                            <div className="answers font-font-s font-normal text-text-color text-xl text-text-color flex flex-col md:flex-row gap-5">
                                {subpunct.answer.map((answer) => (
                                    <label key={answer.id}
                                           className="w-full md:w-1/3 flex flex-row gap-1 items-center justify-start cursor-pointer"
                                           onClick={() => handleRadioChange(item.id, subpunct.id, answer.id)}
                                    >
                                        <input
                                            type="radio"
                                            className="border-2 border-gray-300 p-2 w-full rounded"
                                            name={`${item.id}-${subpunct.id}`}
                                            checked={userAnswers[`${item.id}-${subpunct.id}`] === answer.id}
                                            onChange={() => {}} // Empty onChange to avoid React warning
                                            onClick={(e) => e.stopPropagation()} // Prevent double triggering
                                        />
                                        <span dangerouslySetInnerHTML={{ __html: answer.text }}
                                              className="font-font-s font-normal text-text-color text-xl"
                                        />
                                    </label>
                                ))}
                            </div>
                            {errors[`${item.id}-${subpunct.id}`] && (
                                <p className="error-message text-red-500 font-font-s text-xl">
                                    {errors[`${item.id}-${subpunct.id}`]}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <div className="flex justify-center items-center flex-col">
                <div className="flex gap-4">
                    <SaveTestBtn
                        onClick={handleSave}
                        disabled={Object.keys(errors).length > 0}
                        isSaving={isSaving}
                    />
                </div>
                {saveMessage && <p className="save-message mt-2 text-olive-color font-font-s text-xl">{saveMessage}</p>}
                {mutationError && <ErrorMessage message={mutationError}/>}
            </div>
        </div>
    );
};
