import React from "react";

export const GridAnswerDisplay = ({rightAnswers, userAnswer, score}) => {
    const selectedAnswer = rightAnswers.find(ans => ans.id === userAnswer);
    const correctAnswer = rightAnswers.find(ans => ans.checked);

    return (
        <div className="mt-4 bg-gray-50 rounded">
            <div className="flex items-start gap-2">
                <span className="font-semibold text-gray-700">Punctaj acordat:</span>
                <span className="text-blue-600 font-medium">
                        {score ? score.toFixed(2) : '0'} puncte
                    </span>
            </div>
            <div className="flex items-start gap-2">
                <span className="font-semibold text-gray-700">Răspunsul tău:</span>
                <span className={`${selectedAnswer?.id === correctAnswer?.id ? 'text-green-600' : 'text-red-600'}`}>
                      {selectedAnswer?.text || 'Niciun răspuns selectat'}
                    </span>
            </div>
            <div className="flex items-start gap-2">
                <span className="font-semibold text-gray-700">Răspuns corect:</span>
                <span className="text-green-600">{correctAnswer?.text}</span>
            </div>
        </div>
    );
};
